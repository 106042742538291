<template>
  <div>
    <div style="margin: 10px 0">
      <el-input style="width: 200px" placeholder="请输入工单号" suffix-icon="el-icon-search" v-model="reconum"></el-input>
      <el-select clearable v-model="type" placeholder="请选择类型">
        <el-option label="车间报修" value="车间报修"></el-option>
        <el-option label="维修自查" value="维修自查"></el-option>
        <el-option label="获批技改" value="获批技改"></el-option>
        <el-option label="管理巡审" value="管理巡审"></el-option>
        <el-option label="定期维修" value="定期维修"></el-option>
      </el-select>
      <el-select clearable v-model="state" placeholder="请选择状态">
        <el-option label="工单关闭" value="工单关闭"></el-option>
        <el-option label="作业结束" value="作业结束"></el-option>
        <el-option label="已接单" value="已接单"></el-option>
        <el-option label="维修中" value="维修中"></el-option>
        <el-option label="暂停中" value="暂停中"></el-option>
      </el-select>
      <el-select v-model="lineId" clearable placeholder="请选择车间">
        <el-option v-for="line in lines" :key="line.id" :label="line.name" :value="line.id"></el-option>
      </el-select>
      <el-input style="width: 200px" placeholder="请输入设备名" suffix-icon="el-icon-search" v-model="equipmentName"></el-input>
      <el-date-picker
          v-model="startDate"
          type="date"
          format="yyyy-MM-dd"
          placeholder="选择起始日期"
      ></el-date-picker>
      <el-date-picker
          v-model="endDate"
          type="date"
          format="yyyy-MM-dd"
          placeholder="选择截止日期"
      ></el-date-picker>
      <el-checkbox v-model="important">我负责的车间</el-checkbox>
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>

    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd">新增 <i class="el-icon-circle-plus-outline"></i></el-button>
      <el-popconfirm
          class="ml-5"
          confirm-button-text='确定'
          cancel-button-text='我再想想'
          icon="el-icon-info"
          icon-color="red"
          title="您确定批量删除这些数据吗？"
          @confirm="delBatch"
      >
        <el-button type="danger" slot="reference">批量删除 <i class="el-icon-remove-outline"></i></el-button>
      </el-popconfirm>
      <el-upload action="http://39.106.92.106:9090/failureRecord/import" :show-file-list="false" accept="xlsx" :on-success="handleExcelImportSuccess" style="display: inline-block">
        <el-button type="primary" class="ml-5">导入 <i class="el-icon-bottom"></i></el-button>
      </el-upload>
      <el-button type="primary" @click="exp" class="ml-5">导出 <i class="el-icon-top"></i></el-button>
    </div>

    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"  @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="50"></el-table-column>
      <!--      <el-table-column prop="id" label="ID" width="50" sortable></el-table-column>-->
      <el-table-column prop="id" label="ID" width="60" sortable>
        <!-- 自定义ID列的单元格样式 -->
        <template slot-scope="scope">
          <div :style="getRowStyle(scope.row)">
            {{ scope.row.id }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="reconum" label="工单号" width="80"></el-table-column>
      <el-table-column prop="type" label="类型" width="80"></el-table-column>
      <!--      <el-table-column prop="state" label="状态" width="100"></el-table-column>-->
      <el-table-column prop="state" label="状态" width="80">
        <template slot-scope="scope">
          <div :style="getRowStyle(scope.row)">
            {{ scope.row.state }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="startTime" label="接单时间" width="95"></el-table-column>
<!--      <el-table-column prop="stopTime" label="暂停时间" width="95"></el-table-column>-->
      <el-table-column prop="endTime" label="结束时间" width="95"></el-table-column>
      <el-table-column label="车间" width="95">
        <template v-slot="scope">
          <span>{{ lines.find(v => v.id === scope.row.lineId) ? lines.find(v => v.id === scope.row.lineId).name : ''  }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="equipmentName" label="设备" ></el-table-column>
      <el-table-column label="工时(h)" width="95">
        <template v-slot="scope">
          <span>{{ formatTime(scope.row.workHour)  }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="userId" label="接单人ID" v-if="false"></el-table-column>
      <el-table-column label="维修工">
        <template v-slot="scope">
          <span>{{ getMaintainerNames(scope.row.reconum)  }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="故障描述"></el-table-column>
      <el-table-column prop="shuoming" label="维修说明"></el-table-column>
      <el-table-column prop="analysis" label="维修分析">
        <template slot-scope="scope">
          <span>{{ scope.row.analysis !== null && scope.row.analysis !== undefined ? scope.row.analysis : 'N/A' }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作"  width="300" align="center">
        <template slot-scope="scope">
          <el-row gutter="2">
            <!-- 第一行按钮 -->
            <el-col :span="10">
              <el-button type="primary" plain @click="gotoDetail(scope.row.id)" size="small">
                查看详情
              </el-button>
            </el-col>
            <el-col :span="10">
              <el-button type="primary" plain @click="handleGroup(scope.row.reconum)">
                编辑维修工 <i class="el-icon-document"></i>
              </el-button>
            </el-col>
          </el-row>

          <el-row gutter="2" style="margin-top: 10px;">
            <!-- 第二行按钮 -->
            <el-col :span="10">
              <el-button type="success" @click="handleEdit(scope.row)">
                编辑 <i class="el-icon-edit"></i>
              </el-button>
            </el-col>
            <el-col :span="10">
              <el-popconfirm
                  class="ml-5"
                  confirm-button-text='确定'
                  cancel-button-text='我再想想'
                  icon="el-icon-info"
                  icon-color="red"
                  title="您确定删除吗？"
                  @confirm="del(scope.row.id)"
              >
                <el-button type="danger" slot="reference">
                  删除 <i class="el-icon-remove-outline"></i>
                </el-button>
              </el-popconfirm>
            </el-col>
          </el-row>
        </template>

      </el-table-column>
    </el-table>
    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="30%" :close-on-click-modal="false">
      <el-form label-width="100px" size="small" style="width: 90%">
        <el-form-item label="ID" v-if="false" >
          <el-input v-model="form.id" disabled visible="false" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="工单号" v-if="false" >
          <el-input v-model="form.reconum" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select clearable v-model="form.type" placeholder="请选择类别" style="width: 100%">
            <el-option label="车间报修" value="车间报修"></el-option>
            <el-option label="维修自查" value="维修自查"></el-option>
            <el-option label="管理巡审" value="管理巡审"></el-option>
            <el-option label="获批技改" value="获批技改"></el-option>
            <el-option label="定期维修" value="定期维修"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select clearable v-model="form.state" placeholder="请选择状态" style="width: 100%">
            <el-option label="管理员锁定" value="管理员锁定"></el-option>
            <el-option label="维修中" value="维修中"></el-option>
            <el-option label="暂停中" value="暂停中"></el-option>
            <el-option label="作业结束" value="作业结束"></el-option>
            <el-option label="工单关闭" value="工单关闭"></el-option>
            <el-option label="审批不通过" value="审批不通过"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker v-model="form.startTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="暂停时间">
          <el-date-picker v-model="form.stopTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker v-model="form.endTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="工时(h)">
          <el-input v-model="form.workHour" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="维修分析">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="请输入维修内容"
              v-model="form.analysis">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="备件清单" :visible.sync="dialogFormVisible2" width="60%" :close-on-click-modal="false">
      <el-table :data="recordComponents" border stripe :header-cell-class-name="'headerBg'">
        <el-table-column prop="recordId" label="工单ID" width="80"></el-table-column>
        <el-table-column label="工单号" width="120">
          <template v-slot="scope">
            <span>{{ getReconum(scope.row.recordId) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="compnum" label="备件号"></el-table-column>
        <el-table-column label="备件名">
          <template v-slot="scope">
            <span>{{ getCompName(scope.row.compnum) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="sum" label="数量"></el-table-column>
        <el-table-column prop="price" label="总价"></el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="编辑维修工" :visible.sync="dialogFormVisible3" width="35%" :close-on-click-modal="false">
      <el-button type="primary" @click="handleAddMaintainer(flagReconum)">新增 <i class="el-icon-circle-plus-outline"></i></el-button>
      <el-table :data="recomaintainers" border stripe :header-cell-class-name="'headerBg'">
        <el-table-column prop="id" label="ID" v-if="false"></el-table-column>
        <el-table-column prop="reconum" label="工单号" width="120"></el-table-column>
        <el-table-column prop="userName" label="维修工" width="80"></el-table-column>
        <el-table-column prop="workHour" label="工时" width="80"></el-table-column>
        <el-table-column label="操作"  align="center">
          <template slot-scope="scope">
            <el-button type="success" @click="handleEditMaintainer(scope.row)">编辑工时 <i class="el-icon-edit"></i></el-button>
            <el-popconfirm
                class="ml-5"
                confirm-button-text='确定'
                cancel-button-text='我再想想'
                icon="el-icon-info"
                icon-color="red"
                title="您确定删除吗？"
                @confirm="delMaintainer(scope.row.id,scope.row.reconum)"
            >
              <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="信息" :visible.sync="dialogFormVisible4" width="30%" :close-on-click-modal="false">
      <el-form label-width="100px" size="small" style="width: 90%">
        <el-form-item label="ID" v-if="false" >
          <el-input v-model="form2.id" disabled visible="false" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="工单号" v-if="false" >
          <el-input v-model="form2.reconum" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="维修工ID" v-if="false" >
          <el-input v-model="form2.userId" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="维修工">
          <el-input v-model="form2.userName" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="工时(h)">
          <el-input v-model="form2.workHour" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible4 = false">取 消</el-button>
        <el-button type="primary" @click="save4">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="信息" :visible.sync="dialogFormVisible5" width="30%" :close-on-click-modal="false">
      <el-form label-width="100px" size="small" style="width: 90%">
        <el-form-item label="工单号" >
          <el-input v-model="form2.reconum" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="维修工">
          <el-select clearable v-model="form2.userId" placeholder="请选择维修工" style="width: 100%">
            <el-option v-for="item in users" :key="item.id" :label="item.nickname" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工时(h)">
          <el-input v-model="form2.workHour" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible5 = false">取 消</el-button>
        <el-button type="primary" @click="save4">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="详情" :visible.sync="dialogFormVisible6" width="80%" :close-on-click-modal="false">
      <div class="dialog-content" style="display: flex; gap: 20px;">
        <!-- 左边部分 -->
        <el-card class="box-card" style="flex: 1; font-size: 30px;">
          <div slot="header" class="clearfix">
            <span>报修单</span>
          </div>
          <el-form label-width="100px" size="large">
            <el-form-item label="报修人:">
              <span>{{ users.find(v => v.id === reports.userId) ? users.find(v => v.id === reports.userId).nickname : '' }}</span>
            </el-form-item>
            <el-form-item label="车间">
              <span>{{getLineName2(reports.lineId)}}</span>
            </el-form-item>
            <el-form-item label="设备:">
              <span>{{ reports.equipmentName }}</span>
            </el-form-item>
            <el-form-item label="工单类型:">
              <span>{{ reports.type }}</span>
            </el-form-item>
            <el-form-item label="故障类型:">
              <span>{{ reports.failureType }}</span>
            </el-form-item>
            <el-form-item label="报修时间:">
              <span>{{ reports.recordTime }}</span>
            </el-form-item>
            <el-form-item label="故障时间:">
              <span>{{ reports.failureTime }}</span>
            </el-form-item>
            <el-form-item label="故障描述:">
              <span>{{ reports.description }}</span>
            </el-form-item>
            <el-form-item label="故障图">
              <div style="display: flex; flex-wrap: wrap; gap: 10px;">
                <el-image
                    v-for="(file, index) in reportFiles"
                    :key="index"
                    :src="file.fileUrl"
                    :preview-src-list="reportFiles.map(item => item.fileUrl)"
                    style="width: 100px; height: 100px; margin-bottom: 10px;"
                >
                </el-image>
              </div>
            </el-form-item>
          </el-form>
        </el-card>
        <!-- 右边部分 -->
        <el-card class="box-card" style="flex: 1; font-size: 30px;">
          <div slot="header" class="clearfix">
            <span>工单</span>
          </div>
          <el-form label-width="100px" size="large">
            <el-form-item label="ID">
              <span>{{ records.id }}</span>
            </el-form-item>
            <el-form-item label="工单号">
              <span>{{ records.reconum }}</span>
            </el-form-item>
            <el-form-item label="类型">
              <span>{{ records.type }}</span>
            </el-form-item>
            <el-form-item label="状态">
              <span>{{ records.state }}</span>
            </el-form-item>
            <el-form-item label="接单人">
              <span>{{ users.find(v => v.id === records.userId) ? users.find(v => v.id === records.userId).nickname : '' }}</span>
            </el-form-item>
            <el-form-item label="维修工">
              <template v-slot="scope">
                <span>{{ getMaintainerNames(records.reconum)  }}</span>
              </template>
            </el-form-item>
            <el-form-item label="接单时间">
              <span>{{ records.startTime }}</span>
            </el-form-item>
            <el-form-item label="暂停时间">
              <span>{{ records.stopTime }}</span>
            </el-form-item>
            <el-form-item label="结束时间">
              <span>{{ records.endTime }}</span>
            </el-form-item>
            <el-form-item label="工时(h)">
              <template v-slot="scope">
                <span>{{ formatTime(records.workHour)  }}</span>
              </template>
            </el-form-item>
            <el-form-item label="维修说明">
              <span>{{ records.shuoming }}</span>
            </el-form-item>
            <el-form-item label="维修分析">
              <span>{{ records.analysis }}</span>
            </el-form-item>
            <el-form-item label="评分" v-if="comments.value">
              <el-rate
                  style="margin-top: 8px;"
                  v-model="comments.value"
                  disabled
                  show-score
                  text-color="#ff9900"
                  score-template="{value}">
              </el-rate>
            </el-form-item>
            <el-form-item label="评价" v-if="comments.comment">
              <span>{{comments.comment}}</span>
            </el-form-item>
            <el-form-item label="到达图" v-if="records.avatarUrl">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="records.avatarUrl"
                  :preview-src-list="[records.avatarUrl]">
              </el-image>
            </el-form-item>
            <el-form-item label="完成图">
              <div style="display: flex; flex-wrap: wrap; gap: 10px;">
                <el-image
                    v-for="(file, index) in recordFiles"
                    :key="index"
                    :src="file.fileUrl"
                    :preview-src-list="recordFiles.map(item => item.fileUrl)"
                    style="width: 100px; height: 100px; margin-bottom: 10px;"
                >
                </el-image>
              </div>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import component from "@/views/Component.vue";

export default {
  name: "FailureRecord",
  computed: {
    component() {
      return component
    }
  },
  data() {
    return {
      tableData: [],
      users: [],
      recomaintain: [],
      recomaintainers: [],
      recordComponents: [],
      components: [],
      records: [],
      reports: [],
      comments: [],
      recordFiles: [],
      reportFiles: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      total1: 0,
      pageNum1: 1,
      pageSize1: 10,
      reconum: "",
      startDate : "",
      endDate : "",
      equipmentName: "",
      type: "",
      state: "",
      flagReconum: "",
      lineId: null,
      important: false,
      lines: [],
      form: {},
      form2: {
        // selectedUsers: [], // 存储选中的用户ID
        // reconum: null,
      },
      dialogFormVisible: false,
      dialogFormVisible3: false,
      dialogFormVisible2: false,
      dialogFormVisible4: false,
      dialogFormVisible5: false,
      dialogFormVisible6: false,
      multipleSelection: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      baseURLdata: request.defaults.baseURL,
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      if(this.important === false){
        this.request.get("/failureRecord/page", {
          params: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            reconum: this.reconum,
            state: this.state,
            type: this.type,
            lineId: this.lineId,
            equipmentName: this.equipmentName,
            startDate: this.startDate ? this.startDate.toISOString().split('T')[0] : '',
            endDate: this.endDate ? this.endDate.toISOString().split('T')[0] : '',
          }
        }).then(res => {
          this.tableData = res.data.records
          this.total = res.data.total
        })
      }else{
        this.request.get("/failureRecord/pageFilter", {
          params: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            reconum: this.reconum,
            state: this.state,
            type: this.type,
            lineId: this.lineId,
            equipmentName: this.equipmentName,
            startDate: this.startDate ? this.startDate.toISOString().split('T')[0] : '',
            endDate: this.endDate ? this.endDate.toISOString().split('T')[0] : '',
            userId: this.user.id
          }
        }).then(res => {
          this.tableData = res.data.records
          this.total = res.data.total
        })
      }
      this.request.get("/user").then(res => {
        this.users = res.data;
      });
      this.request.get("/recordMaintainer").then(res => {
        this.recomaintain = res.data;
      });
      this.request.get("/component").then(res => {
        this.components = res.data;
      });
      this.request.get("/line").then(res => {
        this.lines = res.data
      })
      this.request.get("/repairReport").then(res => {
        this.reports = res.data;
      });
    },
    save() {
      this.request.post("/failureRecord", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    save4() {
      this.request.post("/recordMaintainer", this.form2).then(res => {
        if (res.code === '200') {
          this.request.post("/failureRecord/updateWorkHour2/"+this.form2.reconum).then(res => {
            this.$message.success("保存成功")
            this.dialogFormVisible4 = false
            this.dialogFormVisible5 = false
            this.load()
            this.loadOneRecordMaintainer(this.form2.reconum)
          })
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    formatTime(hours) {
      // 将小时数格式化为天时分秒的字符串显示
      const remainingHours = Math.floor(hours);
      const minutes = Math.floor((hours * 60) % 60);
      return `${remainingHours}小时${minutes}分钟`;
    },
    getReconum(recordId) {
      const record = this.tableData.find(v => v.id === recordId);
      if (!record) return '';
      return record ? record.reconum : '';
    },
    getCompName(compnum) {
      const component = this.components.find(v => v.compnum === compnum);
      if (!component) return '';
      return component ? component.name : '';
    },
    getMaintainerNames(reconum) {
      const recomt = this.recomaintain.filter(item => item.reconum === reconum);
      return recomt.map(item => {
        const user = this.users.find(u => u.id === item.userId);

        // Check if workHour is a valid number and not null or undefined
        const workHour = item.workHour != null ? item.workHour.toFixed(2) : '0.00';
        const minutes = Math.floor(workHour * 60);

        const allData = user ? `${user.nickname} (${minutes}分钟)` : '';
        return allData;
      }).join(', ');
    },
    getDescription(reportId){
      const report = this.reports.find(item => item.id === reportId);
      return report.description !== null && report.description !== undefined ? report.description : 'N/A';
    },
    gotoDetail(recordId){
      this.request.get("/failureRecord/"+recordId).then(res => {
        this.records = res.data;
        this.request.get("/repairReport/"+this.records.reportId).then(res => {
          this.reports = res.data;
        });
        this.request.get("/reportFile/getByReportId/"+this.records.reportId).then(res => {
          this.reportFiles = res.data
        })
        this.request.get("/comment/findRecord/"+this.records.id).then(res => {
          this.comments = res.data;
        });
        this.request.get("/recordFile/getByRecordId/"+this.records.id).then(res => {
          this.recordFiles = res.data
        })
      });
      this.dialogFormVisible6 = true
    },
    getLineName2(lineId) {
      const line = this.lines.find(v => v.id === lineId);
      if (!line) return '';
      return line ? line.name : '';
    },
    getMaintainerIds(reconum) {
      const recomt = this.recomaintain.filter(item => item.reconum === reconum);
      console.log(recomt.map(item => item.userId))
      return recomt.map(item => item.userId);
    },
    viewComponent(row){
      this.request.get("/recordComponent/findByRecordId", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          id: row.id,
        }
      }).then(res => {
        this.recordComponents = res.data.records
        this.total1 = res.data.total
      // })
      // this.dialogFormVisible2 = true;

        // 检查是否有返回的数据
        row.hasComponents = this.recordComponents.length > 0;

        if (row.hasComponents) {
          this.dialogFormVisible2 = true;
        } else {
          this.$message.warning("没有找到相关备件记录");
        }
      }).catch(error => {
        console.error("请求错误", error);
      });
    },
    loadOneRecordMaintainer(reconum){
      this.request.get("/recordMaintainer/findByReconum/"+reconum).then(res => {
        this.recomaintainers = res.data;
      });
    },
    handleGroup(reconum) {
      // this.form2 = {
      //   selectedUsers: this.getMaintainerIds(reconum) || [],// 存储选中的用户ID
      //   reconum: reconum,
      // }
      this.flagReconum = reconum
      this.loadOneRecordMaintainer(reconum)
      this.dialogFormVisible3 = true;
    },
    handleGroupUser() {
      this.request.post("/recordMaintainer/handRecoMt", this.form2).then(res => {
        if (res.code === '200') {
          this.$message.success("修改成功")
          this.dialogFormVisible3 = false
          this.load()
          this.loadOneRecordMaintainer(this.form2.reconum)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {}
      this.$nextTick(() => {
        if(this.$refs.img) {
           this.$refs.img.clearFiles();
         }
         if(this.$refs.file) {
          this.$refs.file.clearFiles();
         }
      })
    },
    handleAddMaintainer(reconum) {
      this.dialogFormVisible5 = true
      this.form2 = {
        reconum: reconum,
      }
    },
    handleEdit(row) {
      // this.form = JSON.parse(JSON.stringify(row))
      this.form = {
        id: row.id,
        type: row.type,
        state: row.state,
        startTime: row.startTime,
        stopTime: row.stopTime,
        endTime: row.endTime,
        workHour: row.workHour,
        analysis: row.analysis,
        reportId: row.reportId,
        reconum: row.reconum,
      }
      this.dialogFormVisible = true
      this.$nextTick(() => {
        if(this.$refs.img) {
          this.$refs.img.clearFiles();
        }
        if(this.$refs.file) {
          this.$refs.file.clearFiles();
        }
      })
    },
    handleEditMaintainer(row){
      this.form2 = {
        id: row.id,
        reconum: row.reconum,
        userId: row.userId,
        userName: row.userName,
        workHour: row.workHour,
      }
      this.dialogFormVisible4 = true
    },
    del(id) {
      this.request.delete("/failureRecord/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    delMaintainer(id,reconum) {
      this.request.delete("/recordMaintainer/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
          this.loadOneRecordMaintainer(reconum)
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    getRowStyle(row) {
      if(row.state === '工单关闭'){
        return { backgroundColor: 'lightgreen' }
      }
      if(row.state === '审批不通过'){
        return { backgroundColor: 'lightpink' }
      }
      if(row.state === '作业结束'){
        return { backgroundColor: 'lightblue' }
      }
    },
    delBatch() {
      if (!this.multipleSelection.length) {
        this.$message.error("请选择需要删除的数据")
        return
      }
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/failureRecord/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    reset() {
      this.reconum = ""
      this.startDate = ""
      this.endDate = ""
      this.state = ""
      this.type = ""
      this.lineId = null
      this.important = false
      this.equipmentName = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleSizeChange1(row,pageSize) {
      console.log(pageSize1)
      this.pageSize1 = pageSize
      this.viewChart(row)
    },
    handleCurrentChange1(row,pageNum) {
      console.log(pageNum1)
      this.pageNum1 = pageNum
      this.viewChart(row)
    },
    download(url) {
      window.open(url)
    },
    exp() {
      // 获取筛选条件的值
      const params = new URLSearchParams();
      if (this.startDate) {
        params.append('startDate', this.startDate);
      }
      if (this.endDate) {
        params.append('endDate', this.endDate);
      }

      // 打开新窗口进行导出
      window.open(this.baseURLdata + "/failureRecord/export?" + params.toString());
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    }
  }
}
</script>


<style>
.headerBg {
  background: #eee!important;
}
</style>
